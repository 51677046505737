import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from './axios';
import { TAGS } from 'utils/caching';

export function getVerticals() {
  return useQuery({
    queryKey: ['verticals'],
    queryFn: async () => {
      const { data = [] } = await getAPIClient().get('/verticals');
      return (
        data?.map((e: any) => ({
          id: e.id,
          value: e.name,
          label: e.name
        })) || []
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export function getServices() {
  return useQuery({
    queryKey: ['services'],
    queryFn: async () => {
      const { data = [] } = await getAPIClient().get('/services');
      return (
        data?.map((e: any) => ({
          id: e.id,
          value: e.name,
          label: e.name
        })) || []
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export function getLocations() {
  return useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      const { data = [] } = await getAPIClient().get('/locations');
      return (
        data.map((a: any) => ({
          id: a.id,
          label: a.name,
          value: a.name,
          name: a.name
        })) || []
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export function getDivisions() {
  return useQuery({
    queryKey: ['divisions'],
    queryFn: async () => {
      const { data = [] } = await getAPIClient().get('/divisions');
      return (
        data.map((a: any) => ({
          id: a.id,
          label: a.label,
          value: a.name
        })) || []
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export function getReaderTypes() {
  return useQuery({
    queryKey: ['readerTypes'],
    queryFn: async () => {
      const { data = [] } = await getAPIClient().get('/readerTypes');
      return (
        data.map((a: any) => ({
          id: a.id,
          value: a.id,
          label: a.name
        })) || []
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export function getProductsLevel2() {
  return useQuery({
    queryKey: ['products-level2'],
    queryFn: async () => {
      const { data = [] } = await getAPIClient().get('/products/level2');
      return (
        data.map((a: any, index: number) => ({
          id: a?.id,
          value: a?.name,
          label: a?.name
        })) || []
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export function getSolutionTypesFromProducts(
  level2Products: any[] = [],
  key = 'label'
) {
  const toRemove = [
    'AOD Other',
    'Security',
    'Infrastructure',
    'Data Management',
    'AIOps and Automation'
  ];

  return level2Products.filter(
    (e: any) =>
      !toRemove.some(
        (r: any) => r.toLocaleLowerCase() === e[key]?.toLowerCase()
      )
  );
}

export function getPartnerOfferingsForOfferSelect(partnerId) {
  return useQuery({
    queryKey: [
      TAGS.getPartnerDashboardOfferings(partnerId),
      'partnerOfferings_OfferSelect'
    ],
    queryFn: async () => {
      const { data = [] } = await getAPIClient().get(
        `/dashboard/partner/${partnerId}/offerings`
      );
      return (
        data.map((a: any) => ({
          id: a.id,
          label: a.name,
          value: a.name
        })) || []
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export function getRoles() {
  return useQuery({
    queryKey: ['roles'],
    queryFn: async () => {
      const { data = [] } = await getAPIClient().get('/roles/public');

      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
