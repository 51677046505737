import { useEffect, useState } from 'react';
import { validateWebsite } from '../../../shared/utils';
import InputTextLog from '../InputTextLog';
import styles from './style.module.scss';
import ModerationButtons from 'components/atoms/ModerationButtons';
import { isYoutubeUrl } from 'components/organisms/OfferingGallery/auxFunctions';
import HybridTooltip from 'components/ui/hybrid-tooltip';

const CHAR_LIMIT = 80;

type InputTypes = {
  label?: string;
  id: string;
  name?: string;
  key?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  description?: string;
  value?: any;
  prevValue?: string;
  maxLength?: number;
  min?: string | number;
  max?: string | number;
  required?: boolean;
  fullWidth?: boolean;
  onChange?: any;
  onKeyDown?: any;
  onChangeApprove?: any;
  onError?: any;
  hideRequiredMessage?: boolean;
  checkErrorOnFirstRender?: boolean;
  wasChanged?: boolean;
  hasNote?: string;
  maxWords?: Number;
  approve?: boolean;
  approved?: boolean;
  changed?: boolean;
  dismiss?: boolean;
  invalidNumberInput?: any;
  invalidBigNumberInput?: any;
  convertToTimezone?: boolean; //this should go as true whenever we are displaying dates (if it doesnt have timestamp)
  hideLabel?: boolean;
  isWebsite?: boolean;
  isYoutubeVideo?: boolean;
  icon?: boolean;
  icon_content?: any;
  uncontrolledError?: any;
  showVideoError?: boolean;
};

export default function InputTextPV({
  label = '',
  id,
  name,
  key,
  type = 'text',
  disabled,
  placeholder,
  description,
  value = '',
  prevValue,
  maxLength = 524288,
  min,
  max,
  required,
  fullWidth = false,
  maxWords,
  onChange,
  onChangeApprove,
  onError,
  hideRequiredMessage = false,
  checkErrorOnFirstRender = false,
  wasChanged,
  hasNote = undefined,
  approve = false,
  onKeyDown,
  approved = false,
  changed = false,
  dismiss = false,
  invalidNumberInput,
  invalidBigNumberInput,
  convertToTimezone = false, //this should go as true whenever we are displaying dates (if it doesnt have timestamp)
  hideLabel = false,
  isWebsite = false,
  isYoutubeVideo = false,
  icon = false,
  icon_content = null,
  uncontrolledError = null,
  showVideoError = false
}: InputTypes) {
  const [checkError, setCheckError] = useState(checkErrorOnFirstRender);
  const altDescrCheck =
    (name === 'alt' || name === 'title') && value?.length > CHAR_LIMIT;

  const maxWordsCheck = maxWords
    ? value?.trim()?.split(/\s+/).length > maxWords
    : false;

  const [error, setError] = useState(
    (checkErrorOnFirstRender &&
      ((required && String(value).trim() === '') ||
        (!!value && isYoutubeVideo && !isYoutubeUrl(value)) ||
        (!!value && isWebsite && !validateWebsite(value)[0]))) ||
      altDescrCheck ||
      maxWordsCheck
  );

  useEffect(() => {
    const hasError =
      (required && String(value).trim() === '') ||
      (!!value && isWebsite && !validateWebsite(value)[0]) ||
      (!!value && isYoutubeVideo && !isYoutubeUrl(value)) ||
      altDescrCheck ||
      maxWordsCheck;

    setError(checkError && hasError);
    onError && onError(hasError, id);
    setCheckError(true);
  }, [value]);

  const requiredLabels = label?.includes('*')
    ? label?.substring(label?.indexOf('*'), label?.lastIndexOf('*') + 1)
    : '';

  return (
    <fieldset
      key={key}
      className={`${styles.fieldset} ${fullWidth ? styles.full_width : ''} ${
        error || showVideoError || uncontrolledError ? 'error' : ''
      } relative`}
    >
      <div className={styles.labels_and_moderation}>
        {!hideLabel && (
          <label htmlFor={id} className={styles.label}>
            {`${label.replaceAll('*', '')}`}
            {required && (
              <span
                className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}
              >
                * {requiredLabels}
              </span>
            )}
            {hasNote && <span className={styles.hasNote}>{hasNote}</span>}
            {icon && <HybridTooltip text={icon_content} />}
            {description && <small>{description}</small>}
          </label>
        )}
        {approve && wasChanged && (
          <ModerationButtons
            id={id}
            onChangeApprove={onChangeApprove}
            approved={approved}
            dismissed={dismiss}
          />
        )}
      </div>
      <InputTextLog
        id={id}
        name={name}
        type={type}
        disabled={disabled}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        prevValue={prevValue}
        maxLength={maxLength}
        wasChanged={wasChanged}
        min={min}
        max={max}
        approved={approved}
        changed={changed}
        dismiss={dismiss}
        convertToTimezone={convertToTimezone}
      />
      {(error || showVideoError) &&
      !hideRequiredMessage &&
      !invalidNumberInput?.includes(id) &&
      !invalidBigNumberInput?.includes(id) ? (
        <span className="absolute mt-1">
          {isWebsite || showVideoError
            ? 'Invalid Website'
            : isYoutubeVideo
            ? 'Invalid Youtube URL'
            : altDescrCheck
            ? 'Character limit exceeded'
            : maxWordsCheck
            ? 'Words limit exceeded'
            : 'Required field'}
        </span>
      ) : uncontrolledError ? (
        <span className="absolute mt-1">{uncontrolledError}</span>
      ) : null}
    </fieldset>
  );
}
