export const TAGS = {
  siteAdminSearchValues: 'searchValues',
  // customer stories
  dashboardUseCases: `get-dashboard-use-cases`,
  partnerUseCases: 'partner-use-cases',
  uniquePartnerUseCaseEdit: (id) => `unique-partner-use-case${String(id)}`,
  partnerUseCasesById: (id) => `use-cases-of-partner-${String(id)}`,
  useCaseHistory: 'use-case-history',
  //offerings
  dashboardOfferings: `get-dashboard-offerings`,
  offerings: 'offerings',
  getPartnerDashboardOfferings: (id) =>
    `offerings-of-partner-${String(id)}-on-dashboard`,
  uniqueOffering: (id) => `offering-${String(id)}`,
  offeringHistory: 'offering-history',
  //searchTags
  searchTags: 'searchTags',
  // customer company logs
  companyLogs: 'customer-company-logs',
  // services
  servicesSiteAdministration: 'services-site-administration',
  // survey logs
  surveyLogs: 'survey-logs',
  surveyLogsCompletedForecasts: 'survey-completed-service-forecasts',
  // roles
  roles: 'roles',
  uniqueRole: (id) => `unique-role-${String(id)}`,
  // quarters
  quarters: 'quarters',
  // partner-certifications
  certifications: 'partner-certifications',
  partnerCertificationsById: (id) => `partner-certifications-${String(id)}`,
  //events
  events: 'events',
  partnerEvents: (id) => `parner-events-${String(id)}`,
  providerEvents: (id) => `provider-events-${String(id)}`,
  uniqueEvent: (id) => `unique-event-${String(id)}`,
  // partner contacts
  partnerContacts: (id) => `partner-contacts-${String(id)}`,
  providerContacts: (id) => `provider-contacts-${String(id)}`,
  providerContactsSearchValues: (id) =>
    `provider-contacts-search-values-${String(id)}`,
  partnerContactsSearchValues: (id) =>
    `partner-contacts-search-values-${String(id)}`,
  allPartnerContacts: 'partner-contacts',
  verticals: 'verticals',
  providerTypes: 'provider-types',
  serviceTypes: 'service-types',
  providerInformationData: 'provider-information-data',
  partnerManagers: 'partner-managers-data'
};

export const staleTime = 600000; //10min
