'use client';

import SelectPV from '@/components/atoms/SelectPV';
import InputTextPV from '@/components/molecules/InputTextPV';
import { Button } from '@/components/ui/button';
import { getRoles } from '@/services/dataQueries';
import { t } from 'i18next';
import { signIn, useSession } from 'next-auth/react';
import Image from 'next/image';

import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';

interface IRole {
  name: string;
  id: number;
}

const Login = () => {
  const [role, setRole] = useState<{ label: string; value: number }>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { data = [] } = getRoles();
  const { data: session } = useSession();

  useEffect(() => {
    if (session) {
      window.location.href = '/';
    }
  }, []);

  return (
    <div className="flex items-center justify-center h-full bg-[url('/images/login_background.png')] bg-cover bg-center bg-no-repeat">
      <div className="w-1/3 flex items-center justify-center space-x-4 m-8">
        <span className="text-white text-[34px] font-[350] leading-[40px] text-start">
          {t('sign_in_to')}
        </span>
        <Image
          src="images/insights-logo.svg"
          width={200}
          height={200}
          alt="logo"
        />
      </div>

      <div className="bg-white rounded-md p-8 m-8 w-1/3 space-y-6">
        <p className="text-[20px] font-[325] leading-[24px] text-start">
          {t('welcome_insights')}
        </p>

        <h1 className="text-[45px] font-[350] leading-[24px] text-start">
          {t('sign_in')}
        </h1>

        <InputTextPV
          type="text"
          name="email"
          id="email"
          placeholder={t('email_address')}
          label={t('enter_email')}
          required
          value={email}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setEmail(e.target.value)
          }
        />
        <InputTextPV
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          label="Enter your password"
          required
          value={password}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setPassword(e.target.value)
          }
        />

        <SelectPV
          id="role"
          label={t('choose_role')}
          placeholder={t('choose_role')}
          required
          value={role}
          isMultiple={false}
          onChange={(
            e: React.SetStateAction<{ label: string; value: number }>
          ) => setRole(e)}
          data={data.map((role: IRole) => ({
            label: role.name,
            value: role.id
          }))}
        />

        <Button
          className="w-full"
          disabled={!email || !password || !role}
          onClick={async () => {
            const data = await signIn('credentials', {
              callbackUrl: '/dashboard',
              redirect: false,
              username: email,
              password: password,
              role: role?.value
            });

            if (data?.error) {
              toast.error(t('invalid_login'));
            }

            if (data?.url) {
              window.location.href = data.url;
            }
          }}
        >
          {t('login')}
        </Button>
        <Button
          className="w-full"
          onClick={async () => {
            signIn('okta', { callbackUrl: '/dashboard' });
          }}
        >
          {t('okta_login')}
        </Button>
      </div>
    </div>
  );
};

export default Login;
